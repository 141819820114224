const ALLOWED_VALUES = [
  'email',
  'phoneNumber',
  'fullName',
  'referer',
  'unitUuid',
  'message',
  'projectExternalId',
  'saleStageExternalId'
];

const FIELDS_TO_BE_EMPTIED = [
  'email',
  'phoneNumber',
  'fullName',
];

export const handleFormLeads = (form) => {
  let isSubmitting = false;

  async function handleSubmit(e) {
    e.preventDefault();

    // Skip if already submitting
    if(isSubmitting) {
      return;
    }

    isSubmitting = true;

    try {
      // Get form data
      const formData = new FormData(form);

      //Create an object from the form data entries
      const formDataObject = Object.fromEntries(formData.entries());

      // Remove not wanted fields
      Object.keys(formDataObject).forEach(key => {
        if(!ALLOWED_VALUES.includes(key)) {
          delete formDataObject[key]
        }
      });

      const response = await fetch(
        SKANSKA_ENV.MARKETER_LEAD_URL,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          referrer: '',
          referrerPolicy: 'no-referrer',
          body: JSON.stringify(formDataObject),
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      await response.json();

      form.classList.add('is-submitted');
      // Wipe values after success.
      FIELDS_TO_BE_EMPTIED.forEach(name => {
        if(form.elements[name]) {
          form.elements[name].value = '';
        }
      })
    } catch (error) {
      console.error(error);
    } finally {
      // TODO: Unsure about finally support.
      isSubmitting = false;
    }
  }

  form.addEventListener('submit', handleSubmit);
};
